


























































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';
import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';

import AR from '../components/UI/AR.vue';
import Embeddable from '../components/Common/Embeddable.vue';
import Listings from '../components/Listings.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';

@Component({
  components: {
    Listings,
    SideBySide,
    ItemCycler,
    Embeddable,
    AR,
  },
})
export default class Home extends Mixins(View) {
  private listingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand],
    PageSize: 9,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  private readonly videoVanuatuVacation: EmbeddedContent = {
    Type: EmbeddedContentType.Youtube,
    EmbedId: 'Ys_hUIPta3U',
  }
}
